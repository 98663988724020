import {
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  intervalToDuration,
} from 'date-fns';
import { FLIP_SYMBOL } from '@/shared/utils/env';
import { isNullish } from './guards';
import TokenAmount from './TokenAmount';

const pluralize = (word: string, numb: number): string => (numb !== 1 ? `${word}s` : word);

export const differenceInTimeAgo = (
  time: string,
  ago = true,
  endTime = new Date().toISOString(),
): string => {
  const end = new Date(endTime);
  const timeNumber = Date.parse(time);
  const seconds = differenceInSeconds(end, timeNumber);

  if (seconds < 60) return `${seconds} sec${ago ? ' ago' : ''}`;

  const minutes = differenceInMinutes(end, timeNumber);
  if (minutes < 60) return `${minutes} min${ago ? ' ago' : ''}`;

  const hours = differenceInHours(end, timeNumber);
  if (hours < 48) return `${hours} ${pluralize('hour', hours)}${ago ? ' ago' : ''}`;

  const days = differenceInDays(end, timeNumber);
  return `${days} days${ago ? ' ago' : ''}`;
};

// format to number{s} or number{m} or number{h}
export const formatToApproxTime = (durationSeconds: number) => {
  if (durationSeconds < 60) {
    return `${durationSeconds}s`;
  }

  if (durationSeconds < 3600) {
    return `${Math.floor(durationSeconds / 60)}m`;
  }

  return `${Math.floor(durationSeconds / 3600)}h`;
};

export const pad = (number: number) => String(number).padStart(2, '0');

// eg: "1h 2min 3s", "1day 2h 3min 4s"
export const intervalToDurationWords = (interval: Interval): string => {
  if (isNullish(interval.start) || isNullish(interval.end)) return '??';
  if (interval.end === 0) return '??';

  const duration = intervalToDuration(interval);

  if (duration.months) return '>1 month';
  if (duration.days) {
    return `${pad(duration.days)}${duration.days === 1 ? 'day' : 'days'} ${pad(
      duration.hours!,
    )}h ${pad(duration.minutes!)}min ${pad(duration.seconds!)}s`;
  }
  if (duration.hours)
    return `${pad(duration.hours)}h ${pad(duration.minutes!)}min ${pad(duration.seconds!)}s`;
  if (duration.minutes) return `${pad(duration.minutes)}min ${pad(duration.seconds!)}s`;
  if (duration.seconds) return `${pad(duration.seconds)}s`;
  return '??';
};

export const addToGoogleCalendar = (amount: bigint, startTimestamp: Date, endTimestamp: Date) => {
  const flipAmount = new TokenAmount(amount).toFixed();
  const callbackUrl = process.env.NEXT_PUBLIC_REDEMPTIONS_CALENDAR_CALLBACK_URL;

  const formatDateForCalendar = (date: Date) => format(date, "yyyyLLdd'T'HHmmss");
  const start = formatDateForCalendar(startTimestamp);
  const end = formatDateForCalendar(endTimestamp);

  const GoogleCalenderUrl = 'https://calendar.google.com/calendar/render';

  const queryParams = {
    action: 'TEMPLATE',
    dates: `${start}/${end}`,
    text: `Redeem ${flipAmount} ${FLIP_SYMBOL}`,
    details: `Finalise redemption of ${flipAmount} ${FLIP_SYMBOL} from <a href="${callbackUrl}">${callbackUrl}</a>`,
  };

  const url = `${GoogleCalenderUrl}?${new URLSearchParams(queryParams).toString()}`;

  window.open(url, '_blank');
};

// eg: "1h 2m", "expired", "less than a minute"
export const formatTimeUntilExpiry = (duration: number, expiredText = 'Expired') => {
  if (duration <= 0) {
    return expiredText;
  }

  if (duration <= 60) {
    return 'less than a minute';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  const hoursRepresentation = hours > 0 ? `${hours}h` : '';
  const minutesRepresentation = minutes > 0 ? `${minutes}m` : '';

  return `${hoursRepresentation} ${minutesRepresentation}`;
};
export const getDateTruncatedToHour = (date: Date) => {
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a zero-based value for the month (zero is the first month)
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = '00';
  const seconds = '00';

  return new Date(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
};

export const toISODateString = (date: Date) => date.toISOString().slice(0, 10);

const getDateParts = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');
  return { day, month, year };
};

export const toStartOfUtcDayString = (date: Date) => {
  const { day, month, year } = getDateParts(date);
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const toEndOfUtcDayString = (date: Date) => {
  const { day, month, year } = getDateParts(date);
  return `${year}-${month}-${day}T23:59:59.999Z`;
};

export const eachUtcDayOfInterval = ({ start, end }: { start: Date; end: Date }) => {
  let accumulator = start;
  const days: Date[] = [];

  while (end >= accumulator) {
    if (new Date(toStartOfUtcDayString(accumulator)).getTime() > new Date().getTime()) {
      break;
    }
    days.push(new Date(toStartOfUtcDayString(accumulator)));
    accumulator = addHours(accumulator, 24);
  }
  return days;
};
