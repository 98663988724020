import { HttpClient, type RpcMethod, type RpcParams, type RpcResult } from '@chainflip/rpc';
import { type CfAccountInfo } from '@chainflip/rpc/types';
import { WsProvider, ApiPromise } from '@polkadot/api';
import * as Sentry from '@sentry/react';
import { assetConstants, type ChainflipAsset, type ChainflipChain } from '@/shared/utils/chainflip';

type RpcAsset = (typeof assetConstants)[ChainflipAsset]['rpcAsset'];
type ChainAndAsset = { asset: RpcAsset; chain: ChainflipChain };

export const getChainAndAsset = (asset: ChainflipAsset): ChainAndAsset => {
  const { chain, rpcAsset } = assetConstants[asset];
  return { asset: rpcAsset, chain };
};

export const humanReadableRole = (role: CfAccountInfo['role']) => {
  if (role === 'broker') return 'Broker';
  if (role === 'liquidity_provider') return 'Liquidity Provider';
  if (role === 'validator') return 'Validator';
  return 'Unregistered';
};

export const initRpcWsConnection = async () => {
  let api = null;
  try {
    const wsProvider = new WsProvider(process.env.NEXT_PUBLIC_STATECHAIN_RPC_WS_URL as string);
    api = await ApiPromise.create({ provider: wsProvider, noInitWarn: true });
    return api.isReady;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error connecting to rpc', error);
    return api;
  }
};

const client = new HttpClient(process.env.NEXT_PUBLIC_STATECHAIN_RPC_HTTP_URL as string);

export default async function makeRpcRequest<M extends RpcMethod>(
  method: M,
  ...args: RpcParams[M]
): Promise<RpcResult<M>> {
  try {
    return await client.sendRequest(method, ...args);
  } catch (error) {
    Sentry.captureException(new Error('Error calling rpc method'), {
      extra: { method, args, error },
    });
    throw new Error(`Error calling rpc method ${method}(${JSON.stringify(args)}): ${error}`);
  }
}
