import BigNumber from 'bignumber.js';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { TABLE_POLL_INTERVAL } from '../constants';

import { getBoostFeesAggregatedByLp } from '../queries/boost';
import {
  getAllLpsTotalFeesAndVolumeQuery,
  getFilteredLpAccountsWithBalancesQuery,
  getLpsFeesAndVolumeQuery,
} from '../queries/lp';
import { accountOrdersToLiquidityUsd, type OpenOrdersCacheResult, TokenAmount } from '../utils';
import { type ChainflipAsset } from '../utils/chainflip';
import { useChainflipAssetPrices } from '.';

const sumNullishValues = (...values: (string | number | null | undefined)[]) =>
  values.reduce((acc: number, curr) => acc + Number(curr ?? 0), 0);

const getLpFeesAndFills = (lpIdSs58?: string) => {
  const { data: lpsFillsAndFees, isLoading: lpsFillsAndFeesLoading } = useGqlQuery(
    getAllLpsTotalFeesAndVolumeQuery,
    {
      refetchInterval: TABLE_POLL_INTERVAL,
      context: { clientName: 'lpProcessor' },
      enabled: !lpIdSs58,
    },
  );

  const { data: lpFillsAndFees, isLoading: lpFillsAndFeesLoading } = useGqlQuery(
    getLpsFeesAndVolumeQuery,
    {
      refetchInterval: TABLE_POLL_INTERVAL,
      context: { clientName: 'lpProcessor' },
      variables: { idSs58: lpIdSs58 },
      enabled: Boolean(lpIdSs58),
    },
  );

  if (!lpIdSs58) {
    const { feesEarnedValueUsd, filledAmountValueUsd } =
      lpsFillsAndFees?.limitOrders?.aggregates?.sum ?? {};
    const {
      baseFeesEarnedValueUsd,
      baseFilledAmountValueUsd,
      quoteFeesEarnedValueUsd,
      quoteFilledAmountValueUsd,
    } = lpsFillsAndFees?.rangeOrders?.aggregates?.sum ?? {};
    return {
      lpFees: sumNullishValues(feesEarnedValueUsd, quoteFeesEarnedValueUsd, baseFeesEarnedValueUsd),
      lpFills: sumNullishValues(
        filledAmountValueUsd,
        quoteFilledAmountValueUsd,
        baseFilledAmountValueUsd,
      ),
      lpFillsAndFeesLoading: lpsFillsAndFeesLoading,
    };
  }

  const { feesEarnedValueUsd, filledAmountValueUsd } =
    lpFillsAndFees?.accounts?.nodes[0]?.limitOrders?.aggregates?.sum ?? {};
  const {
    baseFeesEarnedValueUsd,
    baseFilledAmountValueUsd,
    quoteFeesEarnedValueUsd,
    quoteFilledAmountValueUsd,
  } = lpFillsAndFees?.accounts?.nodes[0]?.rangeOrders?.aggregates?.sum ?? {};
  return {
    lpFees: sumNullishValues(feesEarnedValueUsd, quoteFeesEarnedValueUsd, baseFeesEarnedValueUsd),
    lpFills: sumNullishValues(
      filledAmountValueUsd,
      quoteFilledAmountValueUsd,
      baseFilledAmountValueUsd,
    ),
    lpFillsAndFeesLoading,
  };
};

export default function useLpStats(lpIdSs58?: string) {
  const { prices: assetPrices } = useChainflipAssetPrices();

  const { data: liquidityData, isLoading: liquidityDataLoading } = useGqlQuery(
    getFilteredLpAccountsWithBalancesQuery,
    {
      refetchInterval: 10_000,
      context: { clientName: 'statechainCache' },
      variables: { filter: lpIdSs58 },
    },
  );

  const { data: boostFees, isLoading: boostFeesLoading } = useGqlQuery(getBoostFeesAggregatedByLp, {
    refetchInterval: TABLE_POLL_INTERVAL,
    context: { clientName: 'lpProcessor' },
    variables: { idSs58: lpIdSs58 },
  });

  const {
    lpFees: collectedLpFees,
    lpFills: volumeFilled,
    lpFillsAndFeesLoading,
  } = getLpFeesAndFills(lpIdSs58);

  const collectedBoostFees = (
    boostFees?.lps?.nodes.map((node) => Number(node.boostShares.aggregates?.sum?.feeUsd) || 0) ?? []
  ).reduce((a, b) => a + b, 0);

  const pools = liquidityData?.pools?.nodes || [];
  const lpOpenOrders = (liquidityData?.lps?.nodes.map((node) => node.openOrders).flat() ??
    []) as OpenOrdersCacheResult[];

  const deployedLiquidityUsd = lpOpenOrders.reduce(
    (liquidity, { baseAsset, quoteAsset, orders }) => {
      const pool = pools.find((p) => p.baseAsset === baseAsset && p.quoteAsset === quoteAsset);
      return (
        liquidity +
        accountOrdersToLiquidityUsd(
          orders,
          baseAsset,
          quoteAsset,
          assetPrices[baseAsset] ?? 0,
          assetPrices[quoteAsset] ?? 0,
          pool?.rangeOrderPrice ?? 0,
        )
      );
    },
    0,
  );

  const lpBalances = liquidityData?.lps?.nodes.map((node) => node.balances.nodes).flat() ?? [];
  const boostBalances =
    liquidityData?.lps?.nodes.map((node) => node.boostBalances.nodes).flat() ?? [];

  const undeployedLiquidityUsd = lpBalances.reduce((acc, curr) => {
    const asset = curr.asset as ChainflipAsset;
    const usdValue =
      TokenAmount.fromAsset(curr.amount, asset)
        ?.mul(assetPrices[asset] ?? 0)
        .toNumber() ?? 0;
    return acc + usdValue;
  }, 0);

  const boostLiquidityUsd = BigNumber.sum(
    0,
    ...boostBalances.flatMap((balance) => [
      balance.availableAmountValueUsd,
      balance.unavailableAmountValueUsd,
    ]),
  ).toNumber();

  return {
    collectedBoostFees,
    totalCollectedFeesUsd: collectedLpFees + collectedBoostFees,
    deployedLiquidityUsd,
    undeployedLiquidityUsd,
    boostLiquidityUsd,
    totalLiquidityUsd: deployedLiquidityUsd + undeployedLiquidityUsd + boostLiquidityUsd,
    liquidityDataLoading,
    boostFeesLoading,
    volumeFilled,
    lpFillsAndFeesLoading,
  };
}
