import * as Sentry from '@sentry/react';
import BigNumber from 'bignumber.js';
import { isNullish } from './guards';
import { assert } from './helpers';
import { ALL_ZEROS, LEADING_ZEROS, PERIOD_WITH_TRAILING_ZEROS, TRAILING_ZEROS } from './regex';

export const clamp = (value: number | string, min: number, max: number) => {
  const startingValue = typeof value === 'string' ? Number.parseInt(value, 10) : value;
  return Number.isNaN(startingValue) ? min : Math.min(Math.max(startingValue, min), max);
};

export function formatWithCommas(value: string | number): string;
export function formatWithCommas(value: string | number | null): string | null;
export function formatWithCommas(value: string | number | undefined): string | undefined;
export function formatWithCommas(
  value: string | number | null | undefined,
): string | null | undefined;
export function formatWithCommas(
  value: string | number | null | undefined,
): string | null | undefined {
  return value == null ? value : new BigNumber(value).toFormat();
}

export function formatUsdValue(value: BigNumber.Value, precise?: boolean): string;
export function formatUsdValue(value: null, precise?: boolean): null;
export function formatUsdValue(value: undefined, precise?: boolean): undefined;
export function formatUsdValue(
  value: BigNumber.Value | null | undefined,
  precise?: boolean,
): string | null | undefined;
export function formatUsdValue(
  value: BigNumber.Value | null | undefined,
  precise = true,
): string | null | undefined {
  if (value == null) return value;

  let usdAmount = new BigNumber(value);

  assert(usdAmount.gte(0), 'negative amounts not supported');

  if (!usdAmount.eq(0) && usdAmount.lt(0.01)) return `<$0.01`;

  let suffix = '';
  let decimals: number | undefined = 2;

  if (!precise) {
    if (usdAmount.gte(1_000_000_000_000)) {
      usdAmount = usdAmount.shiftedBy(-12);
      suffix = 'T';
    } else if (usdAmount.gte(1_000_000_000)) {
      usdAmount = usdAmount.shiftedBy(-9);
      suffix = 'B';
    } else if (usdAmount.gte(1_000_000)) {
      usdAmount = usdAmount.shiftedBy(-6);
      suffix = 'M';
    }

    if (suffix === '' && usdAmount.gte(10_000)) {
      decimals = 0;
    } else if (suffix !== '') {
      decimals = undefined;
    }
  }

  usdAmount = usdAmount.decimalPlaces(2);

  return `$${usdAmount.toFormat(decimals)}${suffix}`;
}

export function formatLudicrousUsdValue(
  value: BigNumber.Value,
  mandatoryDecimals?: boolean,
): string;
export function formatLudicrousUsdValue(value: null, mandatoryDecimals?: boolean): null;
export function formatLudicrousUsdValue(value: undefined, mandatoryDecimals?: boolean): undefined;
export function formatLudicrousUsdValue(
  value: BigNumber.Value | null | undefined,
  mandatoryDecimals?: boolean,
): string | null | undefined;
export function formatLudicrousUsdValue(
  value: BigNumber.Value | null | undefined,
  mandatoryDecimals = true,
): string | null | undefined {
  if (value == null) return value;

  const usdAmount = new BigNumber(value);

  assert(usdAmount.gte(0), 'negative amounts not supported');

  if (usdAmount.lte(1_000_000_000)) return formatUsdValue(usdAmount, mandatoryDecimals);

  const amountString = usdAmount.toFixed(0);

  const match = /^(\d{1,3})((?:\d{3})+)$/.exec(amountString);

  if (match === null) {
    Sentry.captureException(new Error(`Failed to format ludicrous USD value`), {
      extra: { amountString, usdAmount: usdAmount.toFixed(), value },
    });
    return '$-';
  }

  const [, display, rest] = match;

  return `$${display}e${rest.length}`;
}

export function formatWithNumeral(value: string, mandatoryDecimals?: boolean): string;
export function formatWithNumeral(
  value: string | number,
  mandatoryDecimals?: boolean,
): string | number;
export function formatWithNumeral(value: bigint, mandatoryDecimals?: boolean): string;
export function formatWithNumeral(
  value: null | undefined,
  mandatoryDecimals?: boolean,
): null | undefined;
export function formatWithNumeral(
  value: string | number | null | undefined,
  mandatoryDecimals?: boolean,
): string | number | null | undefined;
export function formatWithNumeral(
  value: BigNumber | null | undefined,
  mandatoryDecimals?: boolean,
): string | number | null | undefined;
export function formatWithNumeral(
  inputValue: BigNumber | bigint | string | number | null | undefined,
  mandatoryDecimals = false,
): BigNumber | string | number | null | undefined {
  if (isNullish(inputValue)) return inputValue;

  let value = new BigNumber(typeof inputValue === 'bigint' ? inputValue.toString() : inputValue);

  if (value.isNaN()) {
    if (inputValue instanceof BigNumber) return NaN;
    // bigints can't be NaN
    return inputValue as string | number;
  }

  let suffix = '';

  if (value.gte(1_000_000_000_000)) {
    value = value.shiftedBy(-12);
    suffix = 'T';
  } else if (value.gte(1_000_000_000)) {
    value = value.shiftedBy(-9);
    suffix = 'B';
  } else if (value.gte(1_000_000)) {
    value = value.shiftedBy(-6);
    suffix = 'M';
  }

  return `${value.decimalPlaces(2).toFormat(mandatoryDecimals ? 2 : undefined)}${suffix}`;
}

export const normalizeZeros = (string: string): string =>
  string
    .replace(PERIOD_WITH_TRAILING_ZEROS, '')
    .replace(ALL_ZEROS, '')
    .replace(LEADING_ZEROS, '')
    .replace(TRAILING_ZEROS, '$1');

export const safeParseInt = (value: string | null | undefined, radix = 10): number | null => {
  if (isNullish(value)) return null;
  const parsed = Number.parseInt(value, radix);
  return Number.isNaN(parsed) ? null : parsed;
};

export const parseIntOrThrow = (value: string | number) => {
  if (typeof value === 'number') return value;
  const n = Number.parseInt(value, 10);
  if (Number.isSafeInteger(n)) return n;
  throw new Error('Failed to safely parse integer');
};

export const pipsToPercentString = (pips: number): string => `${(pips / 100).toFixed(2)}%`;
