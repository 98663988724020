import { type ChainflipAsset, type BaseChainflipAsset } from './chainflip';
import { chainflipAssetMap } from './env';
import { type OpenOrdersCacheResult } from './schemas';
import { liquidityToTokenAmounts } from './tickMath';
import TokenAmount from './TokenAmount';

export type LimitOrderType = 'Sell' | 'Buy';

export const accountOrdersToLiquidityUsd = (
  orders: Pick<OpenOrdersCacheResult['orders'], 'limit_orders' | 'range_orders'>,
  baseAsset: BaseChainflipAsset,
  quoteAsset: ChainflipAsset,
  baseAssetUsdPrice: number,
  quoteAssetUsdPrice: number,
  currentPoolRate: number,
) => {
  const baseToken = chainflipAssetMap[baseAsset];
  const quoteToken = chainflipAssetMap[quoteAsset];

  let usdHoldings = 0;

  for (const order of orders.range_orders) {
    const tokenAmounts = liquidityToTokenAmounts({
      liquidity: order.liquidity.toString(),
      currentRate: currentPoolRate,
      lowerTick: order.range.start,
      upperTick: order.range.end,
      baseAsset: baseAsset as BaseChainflipAsset,
    });

    usdHoldings += quoteAssetUsdPrice * tokenAmounts.quoteAsset.toNumber();
    usdHoldings += baseAssetUsdPrice * tokenAmounts.baseAsset.toNumber();
  }

  for (const order of orders.limit_orders.asks) {
    const baseTokenAmount = new TokenAmount(order.sell_amount, baseToken.decimals);
    usdHoldings += baseAssetUsdPrice * baseTokenAmount.toNumber();
  }

  for (const order of orders.limit_orders.bids) {
    const quoteTokenAmount = new TokenAmount(order.sell_amount, quoteToken.decimals);
    usdHoldings += quoteAssetUsdPrice * quoteTokenAmount.toNumber();
  }

  return usdHoldings;
};

export type RpcOrder = ReturnType<typeof transformCacheOrdersToRpcOrders>[number];

export const transformCacheOrdersToRpcOrders = (orders: OpenOrdersCacheResult[]) =>
  orders
    .map((item) => [
      ...item.orders.limit_orders.asks.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'ask' as const,
      })),
      ...item.orders.limit_orders.bids.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'bid' as const,
      })),
      ...item.orders.range_orders.map((order) => ({
        ...order,
        asset: item.baseAsset,
        type: 'range' as const,
      })),
    ])
    .flat();
