import { type SwapFee } from './graphql/generated/graphql';

export const BLOCK_TIME_IN_SECONDS = 6;
export const HEARTBEAT_BLOCK_INTERVAL = 150;
export const POLKADOT_PREFIX = 0;
export const CHAINFLIP_PREFIX = 2112;
export const TABLE_POLL_INTERVAL = 10_000;

export const DISCORD_URL = 'https://discord.gg/chainflip-community';
export const TELEGRAM_URL = 'https://t.me/chainflip_io_chat';
export const TWITTER_URL = 'https://twitter.com/chainflip';

export const NETWORK_FEE = 'Network fee';
export const POOL_FEE = 'Pool fee';
export const INGRESS_FEE = 'Deposit fee';
export const EGRESS_FEE = 'Broadcast fee';
export const BROKER_FEE = 'Broker fee';
export const BOOST_FEE = 'Boost fee';

export const feeTypeMap: Record<SwapFee['type'], string> = {
  BROKER: BROKER_FEE,
  NETWORK: NETWORK_FEE,
  BOOST: BOOST_FEE,
  INGRESS: INGRESS_FEE,
  EGRESS: EGRESS_FEE,
  LIQUIDITY: POOL_FEE,
};
